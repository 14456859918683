// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as AppSchema from "../../../AppSchema.js";
import * as RisqQuery from "../../../RisqQuery.js";
import * as ServerSyncPipeline from "../../common/ServerSyncPipeline.js";
import * as RisqQuestionLocationsAPI from "../../endpoints/RisqQuestionLocationsAPI.js";

function $$do(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  return Prelude.PromisedResult.map(RisqQuestionLocationsAPI.getAll(undefined, {
                  vessel__id__in: vessels.join(",")
                }, undefined, user), (function (locations) {
                var $$final$1 = AppSchema.RisqQuestionLocations.updateMany($$final, locations);
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final$1
                      };
              }));
}

var ReadServerState = {
  $$do: $$do
};

function $$do$1(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var init = RisqQuery.makeRead();
  return Prelude.PromisedResult.map(RisqQuery.Safe.read({
                  levels: init.levels,
                  answers: init.answers,
                  newAnswers: init.newAnswers,
                  risqVettingPeriods: init.risqVettingPeriods,
                  questions: init.questions,
                  risqQuestionLocations: {
                    TAG: "AnyOf",
                    _0: "vessel_id",
                    _1: vessels
                  }
                }), (function (param) {
                Curry._2(Prelude.$$Array.forEach, param.risqQuestionLocations, (function (extra) {
                        return AppSchema.RisqQuestionLocations.update(initial, extra);
                      }));
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var FillInitialStatus = {
  $$do: $$do$1
};

function $$do$2(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var init = RisqQuery.makeWrite();
  var actions_levels = init.levels;
  var actions_answers = init.answers;
  var actions_newAnswers = init.newAnswers;
  var actions_risqVettingPeriods = init.risqVettingPeriods;
  var actions_questions = init.questions;
  var actions_risqQuestionLocations = AppSchema.RisqQuestionLocations.getActions(initial, $$final);
  var actions = {
    levels: actions_levels,
    answers: actions_answers,
    newAnswers: actions_newAnswers,
    risqVettingPeriods: actions_risqVettingPeriods,
    questions: actions_questions,
    risqQuestionLocations: actions_risqQuestionLocations
  };
  RisqQuery.ActionLogging.info("ServerSyncRisqQuestionsLocations" + ": db actions: ", actions);
  return Prelude.PromisedResult.map(RisqQuery.Safe.write(actions), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$2
};

var ApplyLocallyKnownState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var views = [AppSchema.UserProfiles];

var Views = {
  views: views
};

var CollectFiles = ServerSyncPipeline.MakeFilesCollector(Views);

var CleanUpState = ServerSyncPipeline.MakeStandardCleanup(Views);

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncRisqQuestionsLocations",
      ReadServerState: ReadServerState,
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var Query;

var $$do$3 = include.$$do;

export {
  Query ,
  ReadServerState ,
  FillInitialStatus ,
  UpdateDB ,
  $$do$3 as $$do,
}
/* include Not a pure module */
