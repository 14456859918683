// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../../tree/Level.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Answer from "../../tree/Answer.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as VesselId from "../../vessel/VesselId.js";
import * as ProfileId from "../../accounts/ProfileId.js";
import * as RisqQuery from "../../RisqQuery.js";
import * as LocalImage from "../../../libs/LocalImage.js";
import * as QuestionId from "../../tree/QuestionId.js";
import * as AnswerValue from "../../tree/AnswerValue.js";
import * as Caml_module from "rescript/lib/es6/caml_module.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserProfile from "../../accounts/UserProfile.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as PreVettingQuery from "../../prevetting/PreVettingQuery.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var answer = vnode.attrs.answer;
                var level = vnode.attrs.level;
                var question = vnode.attrs.question;
                var answerProfile = vnode.attrs.answerProfile;
                var allowResolve = vnode.attrs.allowResolve;
                var allowEdit = vnode.attrs.allowEdit;
                var vesselId = vnode.attrs.vesselId;
                var preVettingModule = vnode.attrs.preVettingModule;
                var match = question.kind;
                var tmp;
                var exit = 0;
                if (typeof match === "object") {
                  var variant = match.NAME;
                  if (variant === "MultipleDatesQuestion" || variant === "MultipleIntegersQuestion" || variant === "MultipleTextsQuestion") {
                    var values = Prelude.default(AnswerValue.toHuman(answer.value), []);
                    tmp = match.VAL.labels.map(function (label, index) {
                          return ReactDOM.jsx("span", {
                                      children: label + ": " + Prelude.default(Curry._2(Prelude.$$Array.get, values, index), "None"),
                                      className: "text-center bg-gray my-2 p-2 mx-auto d-block"
                                    });
                        });
                  } else if (variant === "MultipleDatesTextsQuestion") {
                    var options = match.VAL;
                    var values$1 = Prelude.default(AnswerValue.toHuman(answer.value), []);
                    tmp = Curry._2(Prelude.$$Array.concat, options.dates_labels, options.texts_labels).map(function (label, index) {
                          return ReactDOM.jsx("span", {
                                      children: label + ": " + Prelude.default(Curry._2(Prelude.$$Array.get, values$1, index), "None"),
                                      className: "text-center bg-gray my-2 p-2 mx-auto d-block"
                                    });
                        });
                  } else if (variant === "MultipleDatesYesNoQuestion") {
                    var options$1 = match.VAL;
                    var values$2 = Prelude.default(AnswerValue.toHuman(answer.value), []);
                    tmp = Curry._2(Prelude.$$Array.concat, options$1.dates_labels, options$1.bool_labels).map(function (label, index) {
                          return ReactDOM.jsx("span", {
                                      children: label + ": " + Prelude.default(Curry._2(Prelude.$$Array.get, values$2, index), "None"),
                                      className: "text-center bg-gray my-2 p-2 mx-auto d-block"
                                    });
                        });
                  } else {
                    exit = 1;
                  }
                } else if (match === "SignatureQuestion" || match === "PhotoQuestion") {
                  tmp = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(answer.evidences), (function (param) {
                                  return param.id;
                                })), (function (resource) {
                              return ReactDOM.jsx("div", {
                                          children: React.jsx(LocalImage.Resource.make, {
                                                resource: resource
                                              }),
                                          className: "card-image"
                                        });
                            })), Mithril.empty);
                } else {
                  exit = 1;
                }
                if (exit === 1) {
                  tmp = Prelude.default(AnswerValue.toHuman(answer.value), []).map(function (label) {
                        return ReactDOM.jsx("span", {
                                    children: label,
                                    className: "text-center bg-gray my-2 p-2 mx-auto d-block"
                                  });
                      });
                }
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("span", {
                                    children: [
                                      !allowResolve && allowEdit ? React.jsx(Mithril.Link.make, {
                                              href: URLs.preVettingAnswerEditorUrl(false, undefined, undefined, undefined, vesselId, level.id, question, preVettingModule),
                                              className: "btn btn-link btn-action float-right",
                                              children: Caml_option.some(React.jsx(Feather.make, {
                                                        icon: "edit"
                                                      }))
                                            }) : Mithril.empty,
                                      ReactDOM.jsx("div", {
                                            children: question.name,
                                            className: "card-subtitle text-small"
                                          }),
                                      Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, answerProfile, (function (p) {
                                                  return ReactDOM.jsx("div", {
                                                              children: UserProfile.nameWithRank(p),
                                                              className: "card-subtitle text-small"
                                                            });
                                                })), Mithril.empty)
                                    ],
                                    className: "card-header"
                                  }),
                              ReactDOM.jsx("div", {
                                    children: tmp,
                                    className: "card-body"
                                  }),
                              allowResolve ? ReactDOM.jsx("div", {
                                      children: ReactDOM.jsx("div", {
                                            children: ReactDOM.jsxs("a", {
                                                  children: [
                                                    React.jsx(Feather.make, {
                                                          icon: "info"
                                                        }),
                                                    "Details"
                                                  ],
                                                  className: "btn",
                                                  href: URLs.answerDetails(vesselId, level.id, answer.id)
                                                }),
                                            className: "btn-group btn-group-block"
                                          }),
                                      className: "card-footer"
                                    }) : Mithril.empty
                            ],
                            className: "tw-shadow-kaiko-card tw-rounded tw-p-2 tw-m-2"
                          });
              }));
}

var PreVettingAnswerCard = {
  make: make
};

var LevelAccordion = Caml_module.init_mod([
      "ReviewUpload__PrevettingAnswersReview.res",
      155,
      48
    ], {
      TAG: "Module",
      _0: [[
          "Function",
          "make"
        ]]
    });

async function _toggleState(vnode) {
  var match = vnode.state.data;
  if (typeof match === "object") {
    return "Closed";
  }
  var descendentLevels = vnode.attrs.descendentLevels;
  var level = vnode.attrs.level;
  var levelAnswers = Curry._2(Prelude.$$Array.keep, vnode.attrs.answers, (function (a) {
          return Caml_obj.equal(a.level_id, level.id);
        }));
  var subLevels = Level.NaturalSort.sorted(Curry._2(Prelude.$$Array.keep, descendentLevels, (function (d) {
              return Caml_obj.equal(d.parent_id, Caml_option.some(level.id));
            })));
  return {
          TAG: "Open",
          subLevels: subLevels,
          levelAnswers: levelAnswers
        };
}

function make$1(param) {
  return Mithril.view(Mithril.component("Closed"), (function (vnode) {
                var level = vnode.attrs.level;
                var vesselId = vnode.attrs.vesselId;
                var currentProfile = vnode.attrs.currentProfile;
                var profileById = vnode.attrs.profileById;
                var allowResolve = vnode.attrs.allowResolve;
                var descendentLevels = vnode.attrs.descendentLevels;
                var questionById = vnode.attrs.questionById;
                var answers = vnode.attrs.answers;
                var idPrefix = vnode.attrs.idPrefix;
                var allowEdit = vnode.attrs.allowEdit;
                var preVettingModule = vnode.attrs.preVettingModule;
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var subLevels = match.subLevels;
                  tmp = subLevels.length !== 0 ? subLevels.map(function (child) {
                          var descendent = Curry._2(Prelude.$$Array.keep, descendentLevels, (function (l) {
                                  if (l.path.startsWith(child.path)) {
                                    return Caml_obj.notequal(l.id, child.id);
                                  } else {
                                    return false;
                                  }
                                }));
                          return React.jsx(LevelAccordion.make, {
                                      level: child,
                                      descendentLevels: descendent,
                                      vesselId: vesselId,
                                      currentProfile: currentProfile,
                                      profileById: profileById,
                                      allowResolve: allowResolve,
                                      answers: answers,
                                      questionById: questionById,
                                      allowEdit: allowEdit,
                                      idPrefix: idPrefix,
                                      preVettingModule: preVettingModule
                                    });
                        }) : match.levelAnswers.map(function (answer) {
                          var question = QuestionId.$$Map.get(questionById, answer.question_id);
                          var answerProfile = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, answer.profile_id, (function (pId) {
                                      return ProfileId.$$Map.get(profileById, pId);
                                    })), undefined);
                          return React.jsx(make, {
                                      answer: answer,
                                      level: level,
                                      question: question,
                                      answerProfile: answerProfile,
                                      allowResolve: allowResolve ? Answer.questionVisibilityGetter(question)(currentProfile, answerProfile) : false,
                                      allowEdit: Prelude.default(allowEdit, Caml_obj.equal(answer.profile_id, Curry._2(Prelude.OptionExported.$$Option.map, currentProfile, (function (p) {
                                                      return p.id;
                                                    })))),
                                      vesselId: vesselId,
                                      preVettingModule: preVettingModule
                                    });
                        });
                }
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("input", {
                                    hidden: true,
                                    id: idPrefix + "-" + level.id,
                                    name: "accordion-checkbox",
                                    type: "checkbox",
                                    onChange: (function (extra) {
                                        return Prelude.thenDo(_toggleState(vnode), (function (extra) {
                                                      return Mithril.setState(vnode, extra);
                                                    }));
                                      })
                                  }),
                              ReactDOM.jsxs("label", {
                                    children: [
                                      React.jsx(Feather.make, {
                                            icon: "chevron_right",
                                            className: "icon",
                                            style: "top: 0.25rem; position: relative; float: right; margin-right: 0.2rem;",
                                            size: 18
                                          }),
                                      level.name
                                    ],
                                    className: "accordion-header text-primary h5",
                                    htmlFor: idPrefix + "-" + level.id
                                  }),
                              ReactDOM.jsx("div", {
                                    children: tmp,
                                    className: "accordion-body [&>.accordion]:tw-ml-2"
                                  })
                            ],
                            className: "accordion"
                          });
              }));
}

Caml_module.update_mod({
      TAG: "Module",
      _0: [[
          "Function",
          "make"
        ]]
    }, LevelAccordion, {
      make: make$1
    });

async function getActivePeriod(preVettingModule, vesselId) {
  var init = RisqQuery.makeRead();
  var match = await RisqQuery.read({
        levels: init.levels,
        answers: init.answers,
        newAnswers: init.newAnswers,
        risqVettingPeriods: {
          TAG: "Limit",
          _0: 1,
          _1: {
            TAG: "Is",
            _0: "vessel_id",
            _1: vesselId
          }
        },
        questions: init.questions,
        risqQuestionLocations: init.risqQuestionLocations
      });
  return Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(match.risqVettingPeriods), (function (v) {
                return v.id;
              }));
}

function getModuleTitle(preVettingModule) {
  return "Risq";
}

async function load(vnode) {
  var match = vnode.state.data;
  if (typeof match === "object") {
    return ;
  }
  var preVettingModule = vnode.attrs.preVettingModule;
  var Def = {
    preVettingModule: preVettingModule
  };
  var PreVettingQuery$1 = PreVettingQuery.Make(Def);
  var question_ids = vnode.attrs.answers.map(function (a) {
        return a.question_id;
      });
  var profileIds = Prelude.$$Array.keepSome(vnode.attrs.answers.map(function (a) {
            return a.profile_id;
          }));
  var answers = vnode.attrs.answers;
  var answer = Prelude.$$Array.first(vnode.attrs.answers);
  var vessel_id = answer !== undefined ? answer.vessel_id : VesselId.$$null;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord.newUserProfiles = "All", newrecord.userProfiles = {
          TAG: "In",
          _0: profileIds
        }, newrecord));
  var profileById = ProfileId.$$Map.fromArray(Curry._2(Prelude.$$Array.concat, match$1.userProfiles, match$1.newUserProfiles).map(function (i) {
            return [
                    i.id,
                    i
                  ];
          }));
  var init = PreVettingQuery$1.makeRead();
  var match$2 = await PreVettingQuery$1.read({
        levels: Level.ofVessel(vessel_id),
        answers: init.answers,
        newAnswers: init.newAnswers,
        risqVettingPeriods: init.risqVettingPeriods,
        questions: {
          TAG: "In",
          _0: question_ids
        },
        risqQuestionLocations: init.risqQuestionLocations
      });
  var levels = Level.NaturalSort.sorted(match$2.levels);
  var vettingPeriodId = await getActivePeriod(preVettingModule, vessel_id);
  var questionById = QuestionId.$$Map.fromArray(match$2.questions.map(function (q) {
            return [
                    q.id,
                    q
                  ];
          }));
  var isSelfOrAncestor = function (path, level) {
    if (level.path === path) {
      return true;
    } else {
      return path.startsWith(level.path);
    }
  };
  var match$3;
  if (vettingPeriodId !== undefined) {
    var vettingPeriodId$1 = Caml_option.valFromOption(vettingPeriodId);
    match$3 = Curry._2(Prelude.$$Array.partition, answers, (function (a) {
            return Caml_obj.equal(a.vetting_period_id, vettingPeriodId$1);
          }));
  } else {
    match$3 = [
      [],
      answers
    ];
  }
  var answersInCompletedPeriod = match$3[1];
  var answersInActivePeriod = match$3[0];
  var match$4 = Curry._3(Prelude.$$Array.fold, answersInActivePeriod.map(function (a) {
            return a.level_path;
          }), [
        [],
        levels
      ], (function (param, path) {
          var match = Curry._2(Prelude.$$Array.partition, param[1], (function (extra) {
                  return isSelfOrAncestor(path, extra);
                }));
          var branches = Curry._2(Prelude.$$Array.concat, param[0], match[0]);
          return [
                  branches,
                  match[1]
                ];
        }));
  var levelsInActivePeriod = match$4[0];
  var chaptersInActivePeriod = Curry._2(Prelude.$$Array.keep, levelsInActivePeriod, (function (chapter) {
          return chapter.depth === 3;
        }));
  var match$5 = Curry._3(Prelude.$$Array.fold, answersInCompletedPeriod.map(function (a) {
            return a.level_path;
          }), [
        [],
        levels
      ], (function (param, path) {
          var match = Curry._2(Prelude.$$Array.partition, param[1], (function (extra) {
                  return isSelfOrAncestor(path, extra);
                }));
          var branches = Curry._2(Prelude.$$Array.concat, param[0], match[0]);
          return [
                  branches,
                  match[1]
                ];
        }));
  var levelsInCompletedPeriod = match$5[0];
  var chaptersInCompletedPeriod = Curry._2(Prelude.$$Array.keep, levelsInCompletedPeriod, (function (chapter) {
          return chapter.depth === 3;
        }));
  return Mithril.setState(vnode, {
              TAG: "Loaded",
              vessel_id: vessel_id,
              chaptersInActivePeriod: chaptersInActivePeriod,
              levelsInActivePeriod: levelsInActivePeriod,
              answersInActivePeriod: answersInActivePeriod,
              chaptersInCompletedPeriod: chaptersInCompletedPeriod,
              levelsInCompletedPeriod: levelsInCompletedPeriod,
              answersInCompletedPeriod: answersInCompletedPeriod,
              questionById: questionById,
              profileById: profileById
            });
}

function make$2(param) {
  return Mithril.view(Mithril.oninitAsync(Mithril.component("NotReady"), load), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var profileById = match.profileById;
                var questionById = match.questionById;
                var answersInCompletedPeriod = match.answersInCompletedPeriod;
                var levelsInCompletedPeriod = match.levelsInCompletedPeriod;
                var chaptersInCompletedPeriod = match.chaptersInCompletedPeriod;
                var answersInActivePeriod = match.answersInActivePeriod;
                var levelsInActivePeriod = match.levelsInActivePeriod;
                var chaptersInActivePeriod = match.chaptersInActivePeriod;
                var vessel_id = match.vessel_id;
                var currentProfile = State__Memory.Current.get().profile;
                var preVettingModule = vnode.attrs.preVettingModule;
                var allowResolve = vnode.attrs.allowResolve;
                var moduleTitle = "Risq";
                return Curry._2(Prelude.$$Array.concat, Curry._2(Prelude.$$Array.concat, [], Prelude.$$Array.isEmpty(chaptersInActivePeriod) ? [] : [ReactDOM.jsxs("div", {
                                        children: [
                                          ReactDOM.jsx("h5", {
                                                children: moduleTitle + " Active vetting period"
                                              }),
                                          chaptersInActivePeriod.map(function (c) {
                                                return React.jsx(LevelAccordion.make, {
                                                            level: c,
                                                            descendentLevels: Curry._2(Prelude.$$Array.keep, levelsInActivePeriod, (function (l) {
                                                                    return l.path.startsWith(c.path);
                                                                  })),
                                                            vesselId: vessel_id,
                                                            currentProfile: currentProfile,
                                                            profileById: profileById,
                                                            allowResolve: allowResolve,
                                                            answers: answersInActivePeriod,
                                                            questionById: questionById,
                                                            allowEdit: true,
                                                            idPrefix: moduleTitle.toLowerCase() + "-active-period",
                                                            preVettingModule: preVettingModule
                                                          });
                                              })
                                        ],
                                        className: "tw-shadow-kaiko-card tw-rounded tw-p-2 tw-mb-2"
                                      })]), Prelude.$$Array.isEmpty(chaptersInCompletedPeriod) ? [] : [ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("h5", {
                                            children: moduleTitle + " Completed vetting periods"
                                          }),
                                      chaptersInCompletedPeriod.map(function (c) {
                                            return React.jsx(LevelAccordion.make, {
                                                        level: c,
                                                        descendentLevels: Curry._2(Prelude.$$Array.keep, levelsInCompletedPeriod, (function (l) {
                                                                return l.path.startsWith(c.path);
                                                              })),
                                                        vesselId: vessel_id,
                                                        currentProfile: currentProfile,
                                                        profileById: profileById,
                                                        allowResolve: false,
                                                        answers: answersInCompletedPeriod,
                                                        questionById: questionById,
                                                        allowEdit: false,
                                                        idPrefix: moduleTitle.toLowerCase() + "-completed-period",
                                                        preVettingModule: preVettingModule
                                                      });
                                          })
                                    ],
                                    className: "tw-shadow-kaiko-card tw-rounded tw-p-2 tw-mb-2"
                                  })]);
              }));
}

export {
  PreVettingAnswerCard ,
  LevelAccordion ,
  getActivePeriod ,
  getModuleTitle ,
  load ,
  make$2 as make,
}
/* LevelAccordion Not a pure module */
