// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as RisqQuery from "../RisqQuery.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as State__User from "./State__User.js";
import * as RisqAnswerAPI from "../api/endpoints/Answers/risq/RisqAnswerAPI.js";
import * as State__Answers from "./State__Answers.js";
import * as PreVettingQuery from "../prevetting/PreVettingQuery.js";
import * as PreVettingAnswer from "../prevetting/PreVettingAnswer.js";
import * as PreVettingPeriodId from "../prevetting/PreVettingPeriodId.js";
import * as State__Inspections from "./State__Inspections.js";
import * as FutureAttachmentManager from "../attachment_chunks/FutureAttachmentManager.js";

async function getActivePeriodId(preVettingModule, vesselId) {
  var init = RisqQuery.makeRead();
  var match = await RisqQuery.read({
        levels: init.levels,
        answers: init.answers,
        newAnswers: init.newAnswers,
        risqVettingPeriods: {
          TAG: "Limit",
          _0: 1,
          _1: {
            TAG: "Is",
            _0: "vessel_id",
            _1: vesselId
          }
        },
        questions: init.questions,
        risqQuestionLocations: init.risqQuestionLocations
      });
  return Curry._2(Prelude.OptionExported.$$Option.map, Prelude.$$Array.first(match.risqVettingPeriods), (function (v) {
                return v.id;
              }));
}

async function make(vessel_id, level_id, level_path, question_id, question_type, value, remarks, evidences, picture_prompted, preVettingModule) {
  var match = await State__Inspections.getCurrentInspector();
  var profile = await State__Inspections.getCurrentProfile();
  var profileId = profile !== undefined ? Caml_option.some(profile.id) : undefined;
  var vetting_period_id = await getActivePeriodId(preVettingModule, vessel_id);
  var vetting_period_id$1 = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, vetting_period_id, PreVettingPeriodId.zero);
  var Def = {
    preVettingModule: preVettingModule
  };
  var PreVettingQuery$1 = PreVettingQuery.Make(Def);
  var init = PreVettingQuery$1.makeRead();
  var match$1 = await PreVettingQuery$1.read({
        levels: init.levels,
        answers: init.answers,
        newAnswers: {
          TAG: "Limit",
          _0: 1,
          _1: "All"
        },
        risqVettingPeriods: init.risqVettingPeriods,
        questions: init.questions,
        risqQuestionLocations: init.risqQuestionLocations
      });
  var isFirstAnswer = match$1.newAnswers.length === 0;
  if (isFirstAnswer) {
    await State__Answers.Steps.start(match.user);
  }
  var walked_steps = isFirstAnswer ? 0 : State__Answers.Steps.steps();
  var answer = PreVettingAnswer.make(level_id, level_path, vessel_id, question_id, match.inspector_id, profileId, question_type, value, remarks, walked_steps, evidences, picture_prompted, vetting_period_id$1);
  var init$1 = PreVettingQuery$1.makeWrite();
  await PreVettingQuery$1.write({
        levels: init$1.levels,
        answers: init$1.answers,
        newAnswers: [{
            TAG: "Save",
            _0: answer
          }],
        risqVettingPeriods: init$1.risqVettingPeriods,
        questions: init$1.questions,
        risqQuestionLocations: init$1.risqQuestionLocations
      });
  var init$2 = PreVettingQuery$1.makeResponse();
  return {
          levels: init$2.levels,
          answers: init$2.answers,
          newAnswers: [answer],
          risqVettingPeriods: init$2.risqVettingPeriods,
          questions: init$2.questions,
          risqQuestionLocations: init$2.risqQuestionLocations
        };
}

async function update(answer, preVettingModule) {
  var match = await State__Inspections.getCurrentInspector();
  var profile = await State__Inspections.getCurrentProfile();
  var profileId = profile !== undefined ? Caml_option.some(profile.id) : undefined;
  var walked_steps = State__Answers.Steps.steps();
  var newrecord = Caml_obj.obj_dup(answer);
  newrecord.walked_steps = walked_steps;
  newrecord.profile_id = profileId;
  newrecord.user_id = match.inspector_id;
  var Def = {
    preVettingModule: preVettingModule
  };
  var PreVettingQuery$1 = PreVettingQuery.Make(Def);
  var init = PreVettingQuery$1.makeWrite();
  return await PreVettingQuery$1.write({
              levels: init.levels,
              answers: init.answers,
              newAnswers: [{
                  TAG: "Save",
                  _0: newrecord
                }],
              risqVettingPeriods: init.risqVettingPeriods,
              questions: init.questions,
              risqQuestionLocations: init.risqQuestionLocations
            });
}

async function $$delete(answer, preVettingModule) {
  var evidence_ids = answer.evidences.map(function (a) {
        return a.id;
      });
  await FutureAttachmentManager.$$delete(evidence_ids);
  var Def = {
    preVettingModule: preVettingModule
  };
  var PreVettingQuery$1 = PreVettingQuery.Make(Def);
  var init = PreVettingQuery$1.makeWrite();
  return await PreVettingQuery$1.write({
              levels: init.levels,
              answers: init.answers,
              newAnswers: [{
                  TAG: "Delete",
                  _0: answer.id
                }],
              risqVettingPeriods: init.risqVettingPeriods,
              questions: init.questions,
              risqQuestionLocations: init.risqQuestionLocations
            });
}

function upload(answers, preVettingModule) {
  return State__User.Auth.redirectIfUnauthorized(State__User.Auth.required(function (__x) {
                  return RisqAnswerAPI.post(__x, answers);
                }));
}

var User;

var getCurrentInspector = State__Inspections.getCurrentInspector;

var getCurrentProfile = State__Inspections.getCurrentProfile;

var LoggedUser;

export {
  User ,
  getCurrentInspector ,
  getCurrentProfile ,
  getActivePeriodId ,
  make ,
  update ,
  $$delete ,
  upload ,
  LoggedUser ,
}
/* RisqQuery Not a pure module */
