// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../../tree/Level.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as VesselId from "../../vessel/VesselId.js";
import * as RisqQuery from "../../RisqQuery.js";
import * as PageHeader from "../../common/views/PageHeader.js";
import * as PageLayout from "../../common/views/PageLayout.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as UserProfileCard from "../../accounts/components/UserProfileCard.js";
import * as ToastNotification from "../../common/components/ToastNotification.js";

async function update(vnode) {
  var vesselId = VesselId.Route.param("vesselId");
  var system = State__Memory.Current.get();
  var currentProfile = system.profile;
  var init = RisqQuery.makeRead();
  var match = await RisqQuery.read({
        levels: Level.ofVessel(vesselId),
        answers: init.answers,
        newAnswers: init.newAnswers,
        risqVettingPeriods: {
          TAG: "Limit",
          _0: 1,
          _1: {
            TAG: "Is",
            _0: "vessel_id",
            _1: vesselId
          }
        },
        questions: init.questions,
        risqQuestionLocations: init.risqQuestionLocations
      });
  var match$1 = match.risqVettingPeriods.length;
  var hasVettingPeriod = match$1 !== 0;
  return Mithril.setState(vnode, {
              TAG: "Loaded",
              vesselId: vesselId,
              selectedLevel: Prelude.default(Prelude.$$Array.first(match.levels), Level.Defaults.$$null),
              currentProfile: currentProfile,
              hasVettingPeriod: hasVettingPeriod
            });
}

function make(param) {
  return Mithril.view(Mithril.oninitAsync(Mithril.component("NotReady"), update), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return React.jsx(PageLayout.make, {
                              header: Caml_option.some(React.jsx(PageHeader.Full.make, {
                                        content: {
                                          NAME: "Title",
                                          VAL: "RISQ"
                                        }
                                      })),
                              children: Caml_option.some(React.jsx(ToastNotification.make, {}))
                            });
                }
                var hasVettingPeriod = match.hasVettingPeriod;
                var currentProfile = match.currentProfile;
                var selectedLevel = match.selectedLevel;
                var vesselId = match.vesselId;
                return React.jsxs(PageLayout.make, {
                            header: Caml_option.some(React.jsx(PageHeader.Full.make, {
                                      content: {
                                        NAME: "Title",
                                        VAL: "RISQ"
                                      }
                                    })),
                            footer: Caml_option.some(hasVettingPeriod ? ReactDOM.jsx("button", {
                                        children: "Continue",
                                        className: "tw-m-0 tw-border-[0.05rem] tw-border-solid tw-border-[#4b48d6]\n            tw-rounded-[0.1rem] tw-cursor-pointer tw-leading-[1.2rem] tw-text-center\n            tw-no-underline [transition:background_.2s,_border_.2s,_box-shadow_.2s,_color_.2s]\n            tw-select-none tw-bg-[#5755d9] tw-text-white tw-text-[1rem] tw-h-12 tw-px-6\n            tw-py-[0.85rem] tw-w-full disabled:tw-opacity-50",
                                        disabled: Curry._1(Prelude.OptionExported.$$Option.isNone, currentProfile),
                                        onClick: (function (param) {
                                            Mithril$1.route.set(URLs.vesselLevelByKind(vesselId, selectedLevel.id, "risq", undefined, undefined, undefined, undefined, undefined, undefined));
                                          })
                                      }) : null),
                            children: [
                              React.jsx(ToastNotification.make, {}),
                              ReactDOM.jsx("div", {
                                    children: currentProfile !== undefined ? (
                                        hasVettingPeriod ? [
                                            ReactDOM.jsx("p", {
                                                  children: "Your current profile is:",
                                                  className: "tw-m-0 tw-font-bold"
                                                }),
                                            React.jsx(UserProfileCard.make, {
                                                  profile: currentProfile
                                                }),
                                            React.jsx(Mithril.Link.make, {
                                                  href: "/user-profiles",
                                                  className: "tw-bg-white tw-border-[0.05rem] tw-border-solid tw-rounded-[0.1rem] tw-cursor-pointer tw-inline-block tw-leading-[1.2rem] tw-outline-[none] tw-text-center tw-no-underline [transition:background_.2s,_border_.2s,_box-shadow_.2s,_color_.2s] tw-select-none tw-align-middle tw-whitespace-nowrap !tw-mb-[0.4rem] tw-text-[#4b48d6] tw-text-[0.7rem] tw-h-[1.4rem] tw-px-3 tw-py-[0.05rem]",
                                                  children: "Switch profile"
                                                })
                                          ] : ReactDOM.jsxs("div", {
                                                children: [
                                                  ReactDOM.jsxs("h2", {
                                                        children: [
                                                          React.jsx(Feather.make, {
                                                                icon: "alert_triangle",
                                                                className: "mr-2",
                                                                style: "vertical-align: bottom",
                                                                size: 36
                                                              }),
                                                          "RISQ vetting period not configured"
                                                        ],
                                                        className: "text-center text-error"
                                                      }),
                                                  ReactDOM.jsx("p", {
                                                        children: "The RISQ module is enabled, but no vetting period has been set."
                                                      }),
                                                  ReactDOM.jsx("p", {
                                                        children: [
                                                          "Please contact your fleet manager to configure a vetting period.",
                                                          "If the issue persists, reach out to Kaiko Support at support@kaikosystems.com."
                                                        ]
                                                      }),
                                                  ReactDOM.jsx("div", {
                                                        children: ReactDOM.jsx("button", {
                                                              children: "Sync again",
                                                              className: "btn btn-primary btn-rounded"
                                                            }),
                                                        className: "text-right"
                                                      })
                                                ],
                                                style: {
                                                  margin: "4rem auto",
                                                  width: "80%"
                                                }
                                              })
                                      ) : [
                                        ReactDOM.jsx("p", {
                                              children: "You need a profile in order to continue.",
                                              className: "tw-m-0 tw-font-bold tw-text-[#ffb700]"
                                            }),
                                        React.jsx(Mithril.Link.make, {
                                              href: "/user-profiles",
                                              className: "tw-border-[0.05rem] tw-border-solid tw-rounded-[0.1rem] tw-cursor-pointer tw-inline-block tw-leading-[1.2rem] tw-text-center [transition:background_.2s,_border_.2s,_box-shadow_.2s,_color_.2s] tw-select-none tw-align-middle tw-whitespace-nowrap !tw-mb-[0.4rem] tw-bg-[#5755d9] tw-border-[#4b48d6] tw-text-[0.7rem] tw-h-[1.4rem] tw-px-3 tw-py-[0.05rem] tw-text-white",
                                              children: "Manage profiles"
                                            })
                                      ],
                                    className: "tw-flex tw-items-center tw-flex-col tw-p-2 tw-mb-2"
                                  })
                            ]
                          });
              }));
}

export {
  update ,
  make ,
}
/* URLs Not a pure module */
